import logger, { safeWindowReload } from '../../logger/logger';
import { type LiteModeSuggestionNotification } from '../../types';
import { WarningIcon } from '../icons/WarningIcon';
import { useLiteModeToggle } from '../LiteMode';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';

const log = logger.scoped('lite-mode');

export const LiteModeNotificationView = (
  props: BaseNotificationProps & {
    notification: LiteModeSuggestionNotification;
  }
): JSX.Element => {
  const notificationDataSource = useNotificationDataSource();
  const liteModeToggle = useLiteModeToggle();

  const handleCleanup = () => {
    notificationDataSource.dismiss(props.notification.id);
    if (props.closeToast) {
      props.closeToast();
    }
  };

  const handleClose = () => {
    log.info('dismissed notification');
    handleCleanup();
  };

  const handleLiteMode = () => {
    handleCleanup();
    liteModeToggle(true, 'suggested');
    safeWindowReload();
  };

  return (
    <div
      className='
        w-166
        text-main-layer
        rounded-xl
        bg-gradient-to-tr from-yellow-start to-yellow-end
    '
    >
      <div className='flex flex-row w-full h-full items-center justify-center gap-9 px-5 py-4.5'>
        <WarningIcon className='w-7 h-7 fill-current' />

        <div className='flex flex-col w-full h-full items-start justify-center'>
          <div className='text-sm font-bold w-100'>
            We're having trouble playing Luna Park on your device. Lite Mode can
            help by turning off the heaviest features like live video.
          </div>
          <div className='text-sm font-bold mt-2'>
            You can also access Lite Mode in your Settings.
          </div>
        </div>

        <div className='ml-auto flex flex-col gap-2.5 justify-between items-center'>
          <button
            type='button'
            className='w-32 h-10 btn-secondary bg-secondary text-icon-gray'
            onClick={handleLiteMode}
          >
            Lite Mode
          </button>
          <button
            type='button'
            className='w-32 h-10 btn-secondary bg-transparent text-icon-gray'
            onClick={handleClose}
          >
            No Thanks
          </button>
        </div>
      </div>
    </div>
  );
};
